<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="采购员" prop="keyword">
          <el-input
            size="mini"
            v-model.trim="table.params.keyword"
            placeholder="名称/联系方式"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <!--  不是地区账号加地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="!city && !province">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width:100%;"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset2">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showEdit">创建</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:role="{row}">
        <span>{{row.role == 1 ? '管理员' : '员工'}}</span>
      </template>
      <template v-slot:address="{row}">
        <span>{{row.province_str}}{{row.city_str}}</span>
      </template>
      <template v-slot:merchant_name="{row}">
        <template v-if="!!row.merchant_name && row.merchant_name.length > 0">
          <div v-for="(v,i) in row.merchant_name" :key="i">
            <span v-if="!!v">{{v}}</span>
            <span v-if="i < row.merchant_name.length - 1">，</span>
          </div>
        </template>
        <span v-else></span>
      </template>
      <template v-slot:goods_count="{row}">
        <el-link style="padding: 0 10px;" type="primary" @click="selGoods(row)">{{row.goods_count || 0}}</el-link>
      </template>
      <template v-slot:is_send="{row}">
        <el-switch
          v-model="row.is_send"
          :active-value="1"
          :inactive-value="2"
          :active-text="`已${row.is_send == 1 ? '开启' : '关闭'}`"
          @change="handleSend(row)">
        </el-switch>
      </template>
      <template v-slot:is_sorter="{row}">
        <span>{{row.is_sorter == 1 ? '是' : '否'}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="handlePwd(row)">修改密码</el-button>
        <el-button type="text" @click="remove(row)" v-if="is_sys == 1">删除</el-button>
      </template>
    </VTable>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>
    <!-- 修改密码 -->
    <NewPwd ref="newpwd"></NewPwd>
    <!-- 绑定供货商品 -->
    <SelGoods
      :dialogTitle="dialogTitle"
      ref="selGoods"
      :getDataApi="getDataApi"
      :setDataApi="setDataApi"
      :showBind="showBind"
      :sourceType="sourceType"
      @refresh="getTable">
    </SelGoods>

  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import NewPwd from './components/newPwd.vue'
import SelGoods from '@/components/SelGoods.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage'

export default {
  name: 'buyerIndex',
  components: {
    Edit,
    NewPwd,
    SelGoods,
    VTable,
  },
  mixins: [mixinTable],
  computed:{
    ...mapGetters(['areaArr'])
  },
  data() {
    return {
      province: getStorage('province'),  // 有地区就是区账号
      city: getStorage('city'),  // 有地区就是区账号
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      getDataApi: '/admin/supplier/goodsList',
      setDataApi: '/admin/supplier/patch',
      showBind: true,
      sourceType: 2, // 采购员
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "name", label: "采购员名称", hidden: false },
        { name: "account", label: "联系方式", hidden: false},
        { name: "address", label: "所在地区", hidden: false},
        { name: "merchant_name", label: "供应客户", hidden: false},
        { name: "role", label: "角色", hidden: false},
        { name: "goods_count", label: "商品数量", hidden: false},
        { name: "is_send", label: "短信通知", width:'130', hidden: false},
        { name: "is_sorter", label: "提供待分拣", hidden: false},
        { name: "action", label: "操作",width: "160", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          area: [],
          province: '',
          city: '',
          page: 1,
          count: 20
        },
        data: [],
        total: 0,
      },
      dialogTitle: '负责采购商品',
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _area = this.table.params.area;
      if(_area.length == 1) {
        this.table.params.province = _area[0]
      } else if(_area.length == 2) {
        this.table.params.province = _area[0]
        this.table.params.city = _area[1]
      } else {
        this.table.params.province = '';
        this.table.params.city = '';
      }
      this.table.loading = true;
      this.$http.get('/admin/purchase/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 新增、编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 修改密码
    handlePwd(row) {
      let dom = this.$refs.newpwd
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.setConfirm(
        `确认删除操作？`,
        {  id: row.id },
        "/admin/purchase/delete"
      );
    },
    //负者供货商品
    selGoods(row) {
      // 判断有供应客户则不能编辑商品并提示
      if(!!row.merchant_name && row.merchant_name.length > 0) {
        this.$message.warning("请在专供列表查看及修改")
      } else {
        let dom = this.$refs.selGoods
        dom.toggle(true)
        dom.getDetail(row)
        dom = null
      }
    },
    // 短信通知
    handleSend(row) {
      this.$http.post('/admin/supplier/open', {id: row.id}).then(res => {
        if(res.code == 1) {
          this.$message.success(`短信${row.is_send == 1 ? '开启' : '关闭'}成功！`)
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        this.getTable()
      })
    }
    
  }
}
</script>
